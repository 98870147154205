import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { graphApi } from './features/graph/services/graphApi';

import groupMembershipReducer from './features/users/services/roleMembershipSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(graphApi.middleware),
  reducer: {
    [graphApi.reducerPath]: graphApi.reducer,
    roleMembership: groupMembershipReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
