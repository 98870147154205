import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  selectPendingRoleAdds,
  selectPendingRoleDeletes,
  selectSelectedRoleGroupId,
  selectRole,
} from '../services/roleMembershipSlice';
import { useGetRoleGroupACLGroupMembershipQuery } from '../../graph/services/graphApi';

import type { ACLGroup, RBACGroup } from '../../../types';

import GroupDisplayItem from './GroupDisplayItem';

type Props = {
  groups?: RBACGroup[];
};

export default function ACLGroupList({ groups }: Props) {
  const pendingAdds = useAppSelector(selectPendingRoleAdds);
  const pendingDeletes = useAppSelector(selectPendingRoleDeletes);
  const selectedGroupId = useAppSelector(selectSelectedRoleGroupId);
  const dispatch = useAppDispatch();

  const isACLGroup = (group: RBACGroup): group is ACLGroup => {
    return 'parentGroupID' in group;
  };

  const groupIds = groups?.map((group) => group.id!);

  // Graph API queries
  const { data: aclGroups } = useGetRoleGroupACLGroupMembershipQuery(groupIds || [], {
    skip: !groupIds,
  });
  const { data: pendingAddsAclGroups } = useGetRoleGroupACLGroupMembershipQuery(
    pendingAdds.map((group) => group.id!)
  );

  if (!groups) {
    return <div></div>;
  }

  return (
    <div className="">
      <div className="py-4 font-bold">Calculated ACL membership:</div>
      {(pendingAddsAclGroups?.length || 0) + (aclGroups?.length || 0) === 0 ? (
        <div></div>
      ) : (
        <div className="border border-base-200 rounded-lg overflow-hidden">
          <ul className="divide-y divide-base-200">
            {
              // Concatenate pendingAddsAclGroups and aclGroups to get the full list of groups to display
              [...(pendingAddsAclGroups || []), ...(aclGroups || [])].map((aclGroup) => {
                if (isACLGroup(aclGroup)) {
                  return (
                    <GroupDisplayItem
                      key={aclGroup.parentGroupID + aclGroup.id}
                      displayName={aclGroup.displayName}
                      roleId={aclGroup.parentGroupID}
                      selected={selectedGroupId === aclGroup.parentGroupID}
                      added={pendingAddsAclGroups?.indexOf(aclGroup) !== -1}
                      onClick={() => {
                        dispatch(
                          selectRole(
                            [...groups, ...pendingAdds].filter(
                              (g) => g.id === aclGroup.parentGroupID
                            )[0]
                          )
                        );
                      }}
                      deleted={pendingDeletes.some((g) => g.id === aclGroup.parentGroupID)}
                    />
                  );
                }
              })
            }
          </ul>
        </div>
      )}
    </div>
  );
}
