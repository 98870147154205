import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  selectPendingRoleAdds,
  selectPendingRoleDeletes,
  selectSelectedRoleGroupId,
  selectRole,
  addRoleToPendingDeletes,
} from '../services/roleMembershipSlice';
import type { RBACGroup, RoleGroup } from '../../../types';

import GroupDisplayItem from './GroupDisplayItem';

type Props = {
  groups?: RBACGroup[];
};

export default function RoleGroupList({ groups }: Props) {
  const pendingAdds = useAppSelector(selectPendingRoleAdds);
  const pendingDeletes = useAppSelector(selectPendingRoleDeletes);
  const selectedGroupId = useAppSelector(selectSelectedRoleGroupId);
  const dispatch = useAppDispatch();

  const isRoleGroup = (group: RBACGroup): group is RoleGroup => {
    return 'transitive' in group;
  };

  if (!groups) {
    return <div></div>;
  }

  return (
    <div>
      <div className="py-4 font-bold">Role membership:</div>
      {pendingAdds.length + groups.length === 0 ? (
        <div></div>
      ) : (
        <div className="flex flex-row border border-base-200 rounded-lg overflow-hidden">
          <ul className="divide-y divide-base-200 w-full">
            {
              // Concatenate pendingAdds and pendingDeletes to get the full list of groups to display
              [...pendingAdds, ...groups].map((group: RBACGroup) => {
                if (isRoleGroup(group)) {
                  const pendingAdd = pendingAdds.indexOf(group) !== -1;
                  const pendingDelete = pendingDeletes.some((g) => g.id === group.id);
                  const key = pendingAdd || pendingDelete ? group.id + 'pending' : group.id;
                  return (
                    <GroupDisplayItem
                      key={key}
                      displayName={group.displayName!}
                      roleId={group.id!}
                      selected={selectedGroupId === group.id}
                      added={pendingAdd}
                      onClick={() => {
                        dispatch(selectRole(group));
                      }}
                      onDelete={() => {
                        dispatch(addRoleToPendingDeletes(group));
                      }}
                      deleted={pendingDelete}
                    />
                  );
                }
              })
            }
          </ul>
        </div>
      )}
    </div>
  );
}
