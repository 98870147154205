import { useIsAuthenticated } from '@azure/msal-react';
import { Outlet, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SignInButton } from '../features/authentication/components/SignInButton';
import { PermissionsCheck } from '../features/authentication/components/PermissionsCheck';
import RBACManLogo from '../img/rbacman.svg';

import 'react-toastify/dist/ReactToastify.css';

export default function Root() {
  const isAuthenticated = useIsAuthenticated();

  const navLinkClassName = ({ isActive, isPending }: { isActive: boolean; isPending: boolean }) =>
    isActive
      ? 'btn btn-primary normal-case'
      : isPending
      ? 'btn btn-ghost normal-case'
      : 'btn btn-ghost normal-case';

  return (
    <>
      <header className="flex-none h-15 sticky top-0 w-full bg-slate-50 dark:bg-gray-800 z-50">
        <nav className="navbar px-6 py-2.5 max-w-screen-xl mx-auto">
          <div className="navbar-start">
            <NavLink to="/">
              <button className="flex items-center">
                <span className="sr-only">RBACMan</span>
                <img className="mr-3 h-6 sm:h-9" src={RBACManLogo} alt="" />
                <div className="flex flex-col">
                  <span className="text-left whitespace-nowrap text-xl font-semibold mt-1">
                    RBACMan
                  </span>
                  <span className="text-left text-[10px] -mt-1">{__COMMIT_HASH__}</span>
                </div>
              </button>
            </NavLink>
          </div>
          <div className="navbar-center">
            {isAuthenticated ? (
              <div className="order-1 flex w-auto justify-between">
                <ul className="mt-0 flex flex-row space-x-8 font-medium">
                  <li>
                    <NavLink to={`/users`} className={navLinkClassName}>
                      Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/roles`} className={navLinkClassName}>
                      Roles
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={`/acls`} className={navLinkClassName}>
                      ACLs
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="navbar-end flex items-center">
            <PermissionsCheck isAuthenticated={isAuthenticated} />
            <SignInButton />
          </div>
        </nav>
      </header>
      <div className="container mx-auto max-w-screen-xl">
        <div className="flex flex-row justify-start py-5 h-full">
          <Outlet />
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </>
  );
}
