import { useState } from 'react';
import classNames from 'classnames';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';

import {
  selectPendingRoleAdds,
  selectPendingRoleDeletes,
  removeRoleFromPendingAdds,
  removeRoleFromPendingDeletes,
} from '../services/roleMembershipSlice';
import { RBACUser } from '../../../types';

import {
  useAddGroupMemberMutation,
  useRemoveGroupMemberMutation,
} from '../../graph/services/graphApi';
import { toast } from 'react-toastify';

type Props = {
  user?: RBACUser;
};

export default function SubmitChanges({ user }: Props) {
  const dispatch = useAppDispatch();
  // Open a modal to confirm all pending changes when the user clicks the button
  const [showModal, setShowModal] = useState(false);

  const pendingRoleAdds = useAppSelector(selectPendingRoleAdds);
  const pendingRoleDeletes = useAppSelector(selectPendingRoleDeletes);

  const [addGroupMember, addResult] = useAddGroupMemberMutation();
  const [removeGroupMember, removeResult] = useRemoveGroupMemberMutation();

  const modalClassName = classNames('modal fixed inset-0 flex items-center justify-center', {
    'modal-open': showModal,
  });

  const showModalButtonClassName = classNames('btn btn-primary', {
    'btn-disabled': pendingRoleAdds.length === 0 && pendingRoleDeletes.length === 0,
  });

  const handleSubmit = async () => {
    if (user) {
      const addPromises = pendingRoleAdds.map(async (role) => {
        try {
          await addGroupMember({
            groupId: role.id,
            memberId: user.id,
            groupDisplayName: role.displayName,
          }).unwrap();
          dispatch(removeRoleFromPendingAdds(role));
        } catch (error: any) {
          toast(
            `Error adding ${user.displayName} to ${role.displayName}: ${error.data.error.message}`,
            {
              type: 'error',
            }
          );
        }
      });

      const deletePromises = pendingRoleDeletes.map(async (role) => {
        try {
          await removeGroupMember({
            groupId: role.id,
            memberId: user.id,
            groupDisplayName: role.displayName,
          }).unwrap();
          dispatch(removeRoleFromPendingDeletes(role));
        } catch (error: any) {
          toast(
            `Error removing ${user.displayName} from ${role.displayName}: ${error.data.error.message}`,
            {
              type: 'error',
            }
          );
        }
      });

      //Resolve all promises, then close the modal
      Promise.all([addPromises, deletePromises]).then(() => {
        setShowModal(false);
      });
    }
  };

  // If the user is undefined, don't render anything
  if (!user) {
    return <div></div>;
  }

  return (
    <>
      <button
        className={showModalButtonClassName}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Submit changes
      </button>

      <div className={modalClassName}>
        <div className="bg-base-100 rounded-lg p-5">
          <div className="font-bold text-xl mb-2">Confirm changes to {user.displayName}</div>
          <div className="pb-2">
            {pendingRoleAdds.length !== 0 && (
              <div className="py-2">
                <div className="pb-1">Roles to add:</div>
                <div className="border border-base-200 rounded-lg overflow-hidden">
                  <ul className="divide-y divide-base-200">
                    {pendingRoleAdds.map((role) => (
                      <li className="text-success-content bg-success p-2" key={role.id}>
                        {role.displayName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {pendingRoleDeletes.length !== 0 && (
              <div className="py-2">
                <div className="pb-1">Roles to remove:</div>
                <div className="border border-base-200 rounded-lg overflow-hidden">
                  <ul className="divide-y divide-base-200">
                    {pendingRoleDeletes.map((role) => (
                      <li className="text-error-content bg-error p-2" key={role.id}>
                        {role.displayName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="text-base-content mb-4">
            Are you sure you want to submit all pending changes?
          </div>
          <div className="flex justify-end">
            <button
              className="btn btn-ghost mr-2"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
