export const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/d3240d57-3e67-425f-8b50-c7125b49e580',
    clientId: 'd22f263d-5224-41a2-a520-da2e672036b5',
    redirectUri: window.location.origin + '/auth/login',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    'https://graph.microsoft.com/Directory.Read.All',
    'https://graph.microsoft.com/Group.ReadWrite.All',
  ],
};
