import type { RBACUser } from '../../../types';

import RoleGroupList from './RoleGroupList';
import ACLGroupList from './ACLGroupList';

import { useGetUserGroupMembershipQuery } from '../../graph/services/graphApi';

type Props = {
  user?: RBACUser;
};

export default function UserGroupMembership({ user }: Props) {
  const { data: groupMembership } = useGetUserGroupMembershipQuery(user?.id || '', {
    skip: !user, // Skip query if no user selected
  });

  if (!user) {
    return <div></div>;
  }

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col grow-0 w-6/12 pr-4">
        <div className="grow-0">
          <RoleGroupList groups={groupMembership} />
        </div>
      </div>
      <div className="flex-none grow-0 w-6/12 pl-4">
        <ACLGroupList groups={groupMembership} />
      </div>
    </div>
  );
}
