import type { RBACUser } from '../../../types';

type UserInfoProps = {
  user: RBACUser | undefined;
};

export default function UserInfo({ user }: UserInfoProps) {
  return (
    <div>
      {user != undefined ? (
        <div>
          <table className="text-sm table-fixed whitespace-nowrap w-full">
            <tbody>
              <tr>
                <td className="text-left font-bold w-28">Name:</td>
                <td className="pl-2 truncate">{user?.displayName}</td>
              </tr>
              <tr>
                <td className="text-left font-bold">UPN:</td>
                <td className="pl-2 truncate">{user?.userPrincipalName}</td>
              </tr>
              <tr>
                <td className="text-left font-bold">Job Title:</td>
                <td className="pl-2 truncate">{user?.jobTitle}</td>
              </tr>
              <tr>
                <td className="text-left font-bold">Location:</td>
                <td className="pl-2 truncate">{user?.officeLocation}</td>
              </tr>
              <tr>
                <td className="text-left font-bold">Account Status:</td>
                <td className="pl-2 truncate">
                  {' '}
                  {user?.accountEnabled ? (
                    <span className="">Enabled</span>
                  ) : (
                    <span className="">Disabled</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
