import { PublicClientApplication } from '@azure/msal-browser';
import type { IPublicClientApplication } from '@azure/msal-browser';
import { toast } from 'react-toastify';

import { msalConfig, loginRequest } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const acquireAccessToken = async (msalInstance: IPublicClientApplication) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    // User is not logged in, so log them in
    const loginResult = await toast.promise(
      msalInstance.loginPopup(loginRequest),
      {
        error: 'There was an error logging in.',
        pending: 'Logging in...',
        success: 'You have successfully logged in.',
      },
      { closeOnClick: true }
    );
    msalInstance.setActiveAccount(loginResult.account);
  }
  const request = {
    account: activeAccount || accounts[0],
    scopes: loginRequest.scopes,
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};

export const login = async (msalInstance: IPublicClientApplication) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    await msalInstance.loginPopup(loginRequest);
  }
};

export const logout = async (msalInstance: IPublicClientApplication) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    await msalInstance.logoutPopup();
  }
};
