import React from 'react';
import { useState, memo, useEffect } from 'react';
import type { RBACGroup } from '../../../types';
import classNames from 'classnames';

type Props = {
  items: RBACGroup[];
  selectedId?: string;
  onSelect: (value: RBACGroup) => void;
  enabled?: boolean;
};

function Autocomplete({ items, selectedId, onSelect, enabled }: Props) {
  const inputClassName = classNames('input input-bordered w-full');

  const [value, setValue] = useState('');

  // groupList is the filtered list of grpi[s], initially it is the same as items
  const [groupList, setGroupList] = useState<RBACGroup[]>(items);

  // Fill the input with the selected group's name when the selected user changes
  useEffect(() => {
    const selectedUser = items.find((user) => user.id === selectedId);
    if (selectedUser) {
      setValue(selectedUser.displayName!);
    }
  }, [selectedId, items]);

  // Filter userList based on entered value
  useEffect(() => {
    setGroupList(
      items.filter((item) => item.displayName?.toLowerCase().includes(value.toLowerCase()))
    );
  }, [value, items]);

  const handleClick = (item: RBACGroup) => {
    onSelect(item);
    // blur input to close dropdown
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  // Select all text when input is focused
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <div className="dropdown w-72">
      <input
        type="text"
        className={inputClassName}
        placeholder={enabled ? 'Search for a Role group' : 'Select a user first'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        tabIndex={0}
        onFocus={handleFocus}
        disabled={!enabled}
      />
      {groupList.length === 0 ? (
        <div></div>
      ) : (
        <div className="w-72 dropdown-content border border-base-200 bg-base-100 top-14 max-h-[50rem] overflow-auto flex-col rounded-md">
          <ul className="menu menu-compact">
            {groupList
              // this might cause a bug if the displayName is empty
              .sort((a, b) => a.displayName!.localeCompare(b.displayName!))
              .map((item, index) => {
                return (
                  <li
                    key={index}
                    tabIndex={index + 1}
                    onClick={() => {
                      handleClick(item);
                    }}
                    className="border-b last:border-b-0 border-b-base-200 w-full"
                  >
                    <div className="flex flex-col items-start gap-0 w-full">
                      <div className="flex flex-row w-full justify-between align-center">
                        <div className="truncate">{item.displayName}</div>
                      </div>
                      <div className="text-xs w-full truncate" title={item.description}>
                        {item.description}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default memo(Autocomplete);
