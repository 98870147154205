import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import RequireAuth from './features/authentication/components/RequireAuth';
import { msalInstance } from './features/authentication/services/msal';
import ACLs from './routes/acls';
import Roles from './routes/roles';
import Root from './routes/root';
import Users from './routes/users';
import { store } from './store';

import './index.css';

const router = createBrowserRouter([
  {
    children: [
      {
        element: (
          <RequireAuth>
            <Users />
          </RequireAuth>
        ),
        path: '/users',
      },
      {
        element: (
          <RequireAuth>
            <Users />
          </RequireAuth>
        ),
        path: '/users/:userId',
      },
      {
        element: (
          <RequireAuth>
            <Roles />
          </RequireAuth>
        ),
        path: '/roles',
      },
      {
        element: (
          <RequireAuth>
            <Roles />
          </RequireAuth>
        ),
        path: '/roles/:roleId',
      },
      {
        element: (
          <RequireAuth>
            <ACLs />
          </RequireAuth>
        ),
        path: '/acls',
      },
      {
        element: (
          <RequireAuth>
            <ACLs />
          </RequireAuth>
        ),
        path: '/acls/:aclId',
      },
    ],
    element: <Root />,
    path: '/',
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
