import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { acquireAccessToken, logout } from '../services/msal';

export function SignInButton() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  async function handleClick() {
    if (isAuthenticated) {
      logout(instance);
      return;
    }
    await acquireAccessToken(instance);
  }

  return (
    <div className="flex flex-row items-center">
      <button className="btn btn-ghost normal-case" onClick={handleClick}>
        {isAuthenticated ? 'Log out' : 'Log in'}
      </button>
    </div>
  );
}
