import { useGetUsersQuery } from '../features/graph/services/graphApi';
import UserSelect from '../features/users/components/UserSelect';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/useAppDispatch';
import UserGroupMembership from '../features/users/components/UserGroupMembership';
import SubmitChanges from '../features/users/components/SubmitChanges';
import { useState, useEffect } from 'react';
import { clearPendingRoles } from '../features/users/services/roleMembershipSlice';
import GroupSelect from '../features/users/components/GroupSelect';
import { useGetRoleGroupsQuery } from '../features/graph/services/graphApi';
import { addRoleToPendingAdds } from '../features/users/services/roleMembershipSlice';
import UserInfo from '../features/users/components/UserInfo';

export default function Users() {
  const { userId } = useParams<{ userId: string }>(); // Get userId from URL

  // Graph API queries
  const { data: userData, isFetching: userDataIsFetching } = useGetUsersQuery();
  const { data: roleGroupData, isFetching: roleGroupDataIsFetching } = useGetRoleGroupsQuery();
  const user = userData?.find((user) => user.id === userId);
  const dispatch = useAppDispatch();

  // Filters for disabled users with toggle button

  // dispatch clearPendingRoles when userId changes
  useEffect(() => {
    dispatch(clearPendingRoles());
  }, [userId, dispatch]);

  //const allGroups = [...(groupMembership || []), ...(transitiveGroupMembership || [])];

  return (
    <div className="w-full px-6">
      <div className="flex flex-row">
        <div className="w-full">
          <div className="flex flex-row flex-wrap bg-slate-50 dark:bg-gray-800 rounded-lg w-full px-4 py-2 divide-x dark:divide-gray-700">
            <div className="flex flex-row gap-4 justify-between w-6/12 min-w-fit">
              {userDataIsFetching || roleGroupDataIsFetching ? (
                'Loading...'
              ) : (
                <>
                  <div className="w-60">
                    <UserSelect users={userData} selectedId={userId} />
                  </div>
                  <div className="w-80 px-4">
                    <GroupSelect
                      groups={roleGroupData}
                      onGroupClick={(group) => dispatch(addRoleToPendingAdds(group))}
                      enabled={!!user}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-row justify-between w-6/12 min-w-fit">
              <div className="w-96 grow px-4">
                <UserInfo user={user} />
              </div>
              <div className="min-w-fit flex items-center justify-end">
                <SubmitChanges user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-16">
        <UserGroupMembership user={user} />
      </div>
      {/* <div>
        <UserFooter user={user} />
      </div> */}
    </div>
  );
}
