import { useIsAuthenticated } from '@azure/msal-react';

type Props = {
  children: JSX.Element;
};

function RequireAuth({ children }: Props) {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated === true ? children : <></>;
}

export default RequireAuth;
