import Autocomplete from './GroupAutoComplete2';
import { RBACGroup } from '../../../types';

type Props = {
  groups: RBACGroup[] | undefined;
  onGroupClick: (group: RBACGroup) => void;
  enabled?: boolean;
};

export default function GroupSelect({ groups, onGroupClick, enabled }: Props) {
  if (!groups) {
    return <div>No groups to display</div>;
  }
  const groupList = groups.filter((group) => group.displayName && group.id);
  return (
    <div>
      <div className="font-bold pb-2">Search for Role to add:</div>
      <Autocomplete items={groupList} onSelect={onGroupClick} enabled={enabled} />
    </div>
  );
}
