import { useState, useEffect } from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { toast } from 'react-toastify';
import { acquireAccessToken } from '../services/msal';
import { useMsal } from '@azure/msal-react';
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline';

type Props = {
  isAuthenticated: boolean;
};

interface MyToken extends JwtPayload {
  scp: String;
  name: String;
  upn: String;
  wids: [];
}

export function PermissionsCheck({ isAuthenticated }: Props) {
  const { instance } = useMsal();
  const [hasRoles, setHasRoles] = useState(false);

  function NotAuthenticated() {
    return (
      <div>
        <div className="font-bold">Correct roles not detected</div>
        <div>
          Please click{' '}
          <a
            className="underline"
            target="_blank"
            href="https://portal.azure.com/?feature.msaljs=false#view/Microsoft_Azure_PIMCommon/ActivationMenuBlade/~/aadmigratedroles/provider/aadgroup"
          >
            here
          </a>{' '}
          to activate a role. You will need one of IT Daily Ops Roles, Directory Writers, or Group
          Administrator roles. Once activated, sign out and back in to RBACMan.
        </div>
      </div>
    );
  }

  async function checkPermissions() {
    const token = await acquireAccessToken(instance);
    const decodedToken = jwt_decode<MyToken>(token);

    // Checks if user has Directory Writers, Users, and Group Administrator roles activated
    if (
      decodedToken.wids.some(
        (wid: string) =>
          [
            'fe930be7-5e62-47db-91af-98c3a49a38b1',
            'fdd7a751-b60b-444a-984c-02652fe8fa1c',
            '9360feb5-f418-4baa-8175-e2a00bac4301',
          ].indexOf(wid) >= 0
      )
    ) {
      toast.success(<div>Correct roles detected</div>);
      setHasRoles(true);
    } else {
      toast.error(<NotAuthenticated />, {
        autoClose: false,
        hideProgressBar: true,
        draggable: false,
      });
      setHasRoles(false);
    }
  }

  //if user is logged in it will check for role permissions
  useEffect(() => {
    if (isAuthenticated) {
      checkPermissions();
    } else {
    }
  }, [isAuthenticated, setHasRoles]);

  return (
    <div className="text-center pr-4">
      {isAuthenticated ? (
        <div>
          <button
            className={
              hasRoles
                ? 'btn btn-outline btn-success btn-square border-0'
                : 'btn btn-outline btn-error btn-square border-0'
            }
            type="button"
            onClick={checkPermissions}
          >
            {hasRoles ? (
              <ShieldCheckIcon className="w-7 h-7" />
            ) : (
              <ShieldExclamationIcon className="w-7 h-7" />
            )}
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
